<template>
    <div class="reg">
        <div class="regtop flexab">
            <img :src="utils.loadImg('logo2.png')" alt=""  height="26" class="point" @click="$router.replace({ name: 'login' })" />
            <div class="rig flex flexa padr40 point" @click="$router.replace({
                path: '/register',
                state: {
                    tab: 2
                }
            })">
                <img :src="utils.loadImg('ma.png')" alt="" width="25" height="25" />
            </div>
        </div>

        <div class="regmid bgf">
            <div class="midhead borbot font14 col9">
                <p class="col24">01.账号信息</p>
                <div class="pad14">
                    <img v-if="step>=2"  :src="utils.loadImg('line-a.png')" alt="">
                    <img v-else :src="utils.loadImg('line.png')" alt="">
                </div>
                <p :class="step>=2?'col24':''">02.身份验证</p>
                <div class="pad14">
                    <img v-if="step>=3"  :src="utils.loadImg('line-a.png')" alt="">
                    <img v-else :src="utils.loadImg('line.png')" alt="">
                </div>
                <p :class="step>=3?'col24':''">03.修改密码</p>
                <div class="pad14">
                    <img v-if="step>=4"  :src="utils.loadImg('line-a.png')" alt="">
                    <img v-else :src="utils.loadImg('line.png')" alt="">
                </div>
                <p :class="step>=4?'col24':''"> 04.完成</p>
            </div>
            <div v-if="step==1" class="wmid">
                <p class="h40"></p>
                <ul class="tabs">
                    <li @click="tab=1;" :class="tab==1?'act':''">通过手机号找回<p></p> </li>
                    <li @click="tab=2;" :class="tab==2?'act':''">通过邮箱号找回<p></p> </li>
                </ul>
                <p class="h40"></p>

                <el-input v-if="tab==1" v-model="param1.mobile" placeholder="请输入您的手机号" @blur="yzPhone(param1.mobile)" size="large"/>
                <el-input v-if="tab==2" v-model="param1.mobile" placeholder="请输入您的邮箱号" @blur="yzEmail(param1.mobile)" size="large"/>
                <p class="h24"></p>
                <div class="flex nowrap">
                    <el-input v-model="param1.code"  placeholder="输入验证码" size="large"/>
                    <img @click="getcodePic" class="img1" :src="codeImg" alt="" srcset="">
                    <!-- <indentify @codeCge="codeCge" class="img1" ref="mychild" ></indentify> -->
                </div>
                <p class="h45"></p>

                <el-button @click="next1" type="primary" class="w100" size="large" >
                    下一步
                </el-button>
            </div>

            <div v-else-if="step==2" class="step2">
                <ul>
                    <li>
                        <label for="">已绑定{{tab==1?'手机':'邮箱'}}号：</label>
                        <p>{{param1.mobile}} </p>
                    </li>
                    <li>
                        <label for="">{{tab==1?'手机':'邮箱'}}验证码：</label>
                        <el-input v-model="info.authCode" class="w227" placeholder="输入验证码" size="large"/>
                        <button @click="handleGetSms" class="btn1">{{yzmSend ? '重新发送('+ time+'s)' : '获取验证码'}}</button>
                    </li>
                    <li>
                        <label for=""></label>
                        <el-button @click="next2" type="primary" class="w125" size="large" >
                            下一步
                        </el-button>
                    </li>
                </ul>

            </div>

            <div v-if="step==3" class="step3">
                <h1 class="col24 fontw6 font22 padb14 tc">账号设置新密码</h1>
                <p class="colb6 font12 lh24 padb24 tc">支持字母、数字等字母密码进行设置</p>

                <el-input placeholder="请输入新密码" v-model="param.password" size="large" type="password" />
                <p class="h24"></p>
                <el-input placeholder="确认密码" v-model="afterPassword" size="large" type="password"/>
                <p class="h45"></p>
                <el-button @click="downten()" type="primary" class="w100" size="large" >
                    提交
                </el-button>
                <p class="h30"></p>
            </div>

            <div v-if="step==4" class="step4">
                <img :src="utils.loadImg('success.png')" alt="" width="120" height="120" srcset="">
                <h1 class="col24 font22 font22 fontw6 padt30">密码修改成功</h1>
                <div class="font12 flex flexa padt14 padb30">
                   <span class="colblue padt3">{{ten}}S</span>
                   &nbsp;
                   <span class="col6">后自动跳转登录页</span>
                </div>

                <el-button @click="$router.push('/login')" type="primary" class="w172" size="large" >
                    前往登录页
                </el-button>
            </div>
        </div>
        <div class="mw">
            <p class="col6 tc font12 padt40 lh20">版权所有：Copyright 2021 版权所有 海量数据</p>
            <p class="col6 tc font12 lh20">京ICP备10213903号-3 京公网安备11010802016346</p>
        </div>
    </div>
</template>
<script>
import { sendVerificationCode , sendResetPasswordEmail, init, checkMobilDraw, verification, editAccountPassword  } from "@/utils/api1.js"

export default {
    components: {
        // indentify: () => import('@/components/identify.vue')
    },
    data(){
        return{
            info:{
                mobile: '',
                authCode: '',
            },
            param:{
                password:'',
            },
            afterPassword:'',
            step: 1,
            tab:1,
            yzm: '',            
            time:60,
            ten:10,
            yzmSend: false,
            timmer: null,
            deadline: Date.now() + 1000 * 10 ,
            codeImg: '',
            param1:{
                captchaCode: '',
                captchaId: '',
                mobile: ''
            },
        }
    },
    mounted(){
        var that = this;
        that.getcodePic();
    },
    methods: {
        // 获取验证码图片
        getcodePic(){
            var that = this;
            init().then(res=>{
                console.log('验证码',res);
                // this.param1.captchaId = res;
                if(res){
                    that.codeImg = `${ res.codeImg }?uuid=${ res.uuid }`
                    that.param1.uuid = res.uuid
                }
            })
        },
        next1(){
            let that = this
            checkMobilDraw(this.param1).then(res=>{
                console.log('获取结果',res)
                if(res){
                   that.step = 2;
                }
            })
        },
        handleGetSms() {
            let that = this;
            if (that.yzmSend) return;

            if(that.tab==1){
                sendVerificationCode({mobile:that.param1.mobile}).then(res=>{
                    console.log(res);
                    if(res){
                        that.$message.success("验证码发送成功！");
                    }
                });
            }else{
                sendResetPasswordEmail({mobile:that.param1.mobile}).then(res=>{
                    console.log(res);
                    if(res){
                        that.$message.success(res.message);
                    }
                });
            }
            
            if(!that.yzmSend){
                that.yzmSend = true;
                that.timmer = setInterval(function () {
                    if (that.time == 1) {
                        that.time = 60;
                        that.yzmSend = false;
                        clearInterval(that.timmer);
                        return;
                    }
                    that.time--;
                }, 1000);
            }
        },
        next2(){
            this.info.mobile = this.param1.mobile;
            verification(this.info).then(res=>{
                console.log(res);
                if(res){
                    this.step = 3;
                    this.time = 60;
                    this.yzmSend = false;
                }
            })
        },

        downten(){
            var that = this;
            if(that.param.password != that.afterPassword ){
                that.$message.warning('输入密码不同！');
                return
            }
            that.param.mobile = that.info.mobile;
            console.log(that.param)
            editAccountPassword(that.param).then(res=>{
                console.log(res);
                if(res){
                    let mytimmer;
                    mytimmer = setInterval(function () {
                        if (that.ten == 1) {
                            clearInterval(mytimmer);
                            that.$router.push('/login');
                            that.ten = 10;
                            return;
                        }
                        that.ten--;
                    }, 1000);
                    that.step=4;
                }
            })
        },

        
        

        

        onFinish(){
            console.log('完成');
            this.$router.push('/login');
        },

        yzPhone(phone){
            console.log(phone);
            if (!/^1(3|4|5|6|7|8|9)\d{9}$/.test(phone)){
               console.log(phone);
               this.$message.warning('请输入正确手机号！')
            }
        },

        yzEmail(email){
            console.log(email);
            let reg = new RegExp("^[a-z0-9]+([._\\-]*[a-z0-9])*@([a-z0-9]+[-a-z0-9]*[a-z0-9]+.){1,63}[a-z0-9]+$"); //正则表达式
            if (!reg.test(email)){
               this.$message.warning('请输入正确邮箱号！')
            }
        },
    },
}
</script>
<style lang="scss" scoped>
.w227{ width: 227px;}
.w172{ width: 172px;}
.w125{ width: 125px;}
.padt3{ padding-top: 3px;}
.mw{
    min-width: 1090px;
}
.img1{
    width: 96px;
    height: 38px;
    border-radius: 2px;
    border: 1px solid #E6E8EB;
    margin-left: 10px;
}
.step4{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px 20px;
}
.step2{
    padding-top: 60px;
    display: flex;
    justify-content: center;
    >ul{
        color: #3F4651;
        font-size: 12px;
        >li{
            padding-bottom: 36px;
            display: flex;
            align-items: center;
            >label{
                width: 86px;
                min-width: 86px;

            }
        }
    }
}
.step3{
    padding-top: 45px;
    width: 320px;
    margin:0 auto;
}
.regtop{
    background: #fff;
    height: 50px;
    padding: 0 25px;
    position: fixed;
    top: 0;
    width: 100%;
    min-width: 1090px;
    box-sizing: border-box;
}
.reg{
    padding: 90px 0 35px;
    background: #F6F6F6;
    min-height: 100vh;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    .regmid{
        width: 1090px;
        margin: 0 auto;
        box-sizing: border-box;
        padding: 0 64px 57px;
        min-height:78vh;
        // height: calc(100vh-170px);
    }
    .wmid{
        width: 320px;
        margin: 0 auto;
    }
    .midhead{
        line-height: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        
    }
}
.btn1{
    margin-left: 14px;
    border-radius: 2px;
    color: #71767E;
    font-size: 12px;
    width: 155px;
    min-width: 155px;
    height: 40px;
}
.tabs{
    display: flex;
    justify-content: space-around;
    font-size: 18px;
    color: #242424;
    li>p{
        width: 80px;
        height: 4px;
        background: #fff;
        margin: 16px auto;
    }
    li{
        cursor: pointer;
    }
    .act{
        color: #FF6A00;
        >p{
            background: #FF6A00;
        }
    }
}
</style>